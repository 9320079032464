import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.min";

const lightbox = new PhotoSwipeLightbox({
  gallery: ".product-gallery, .product-gallery-main",
  children: "a",
  showHideAnimationType: "none",
  zoomAnimationDuration: false,
  bgClickAction: "close",
  zoom: false,
  clickToCloseNonZoomable: false,
  tapAction: "",
  loop: false,
  padding: {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  },
  pswpModule: () => import("photoswipe")
});
lightbox.init();
