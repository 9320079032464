import React  from 'react';
import Polyglot from "node-polyglot";
import egumaGlobalTranslations from "egumaGlobalTranslations"; // added in externals in webpack
const polyglot = new Polyglot({ phrases: egumaGlobalTranslations });

const isIE = () => {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return (msie > 0 || trident > 0);
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {

  }

  render() {
    if (this.state.hasError) {
      if (isIE()) {
        return <div className="alert alert-danger">
          { polyglot.t("Shop.Error.BrowserOutdated")}
        </div>;
      }
      return <div className="alert alert-warning">
        { polyglot.t("Shop.Error.ErrorLoadingWebsite")}
      </div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;