import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "./Global/ErrorBoundary";
import "./main.scss";

/*
 * Bootstrap dependencies
 * */
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/alert";

import "./jQuery/navigation";
import "./jQuery/swiper";
import "./jQuery/photoswipe";

import "vanilla-cookieconsent/dist/cookieconsent";

const SeatingApp = React.lazy(() => import("./Seating/App"));
const EventQuestionsApp = React.lazy(() => import("./EventQuestions/App"));
const CalendarApp = React.lazy(() => import("./Calendar/App"));
const CalendarWithPilatusResy = React.lazy(() =>
  import("./CalendarWithPilatusResy/App")
);
const CalendarDayPickerApp = React.lazy(() =>
  import("./CalendarDayPicker/App")
);
const TicketMediumChoiceApp = React.lazy(() =>
  import("./TicketMediumChoice/App")
);
const CheckBalanceApp = React.lazy(() => import("./CheckBalance/App"));

const RechargeVoucherApp = React.lazy(() => import("./RechargeVoucher/App"));

const ProductApp = React.lazy(() => import("./Product/App"));

const CustomerLoginApp = React.lazy(() => import("./CustomerLogin/App"));
const CustomerAccountApp = React.lazy(() => import("./CustomerAccount/App"));
const CustomerOrdersApp = React.lazy(() => import("./CustomerOrders/App"));

const Wrapper = ({ children }) => (
  <ErrorBoundary>
    <Suspense fallback={<div />}>{children}</Suspense>
  </ErrorBoundary>
);

const pickerDiv = document.getElementById("root");
if (pickerDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <SeatingApp />
    </Wrapper>,
    pickerDiv
  );
}

const questionsDiv = document.getElementById("questions");
if (questionsDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <EventQuestionsApp />
    </Wrapper>,
    questionsDiv
  );
}

const calendarDiv = document.getElementById("root-calendar");
if (calendarDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CalendarApp />
    </Wrapper>,
    calendarDiv
  );
}

const calendarWithPilatusResyDiv = document.getElementById(
  "root-calendar-with-pilatus-resy"
);
if (calendarWithPilatusResyDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CalendarWithPilatusResy />
    </Wrapper>,
    calendarWithPilatusResyDiv
  );
}

const ticketMediumChoiceDiv = document.getElementById(
  "root-ticket-medium-choice"
);
if (ticketMediumChoiceDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <TicketMediumChoiceApp />
    </Wrapper>,
    ticketMediumChoiceDiv
  );
}

const calendarDayPickerDiv = document.getElementById(
  "root-calendar-day-picker"
);
if (calendarDayPickerDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CalendarDayPickerApp />
    </Wrapper>,
    calendarDayPickerDiv
  );
}

const checkBalanceDiv = document.getElementById("root-check-balance");
if (checkBalanceDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CheckBalanceApp />
    </Wrapper>,
    checkBalanceDiv
  );
}

const rechargeVoucherDiv = document.getElementById("root-recharge-voucher");
if (rechargeVoucherDiv) {
  ReactDOM.render(
    <Wrapper>
      <RechargeVoucherApp />
    </Wrapper>,
    rechargeVoucherDiv
  );
}

const productAppDiv = document.getElementById("root-product-picker");
if (productAppDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <ProductApp />
    </Wrapper>,
    productAppDiv
  );
}

const customerLoginDiv = document.getElementById("root-customer-login");
if (customerLoginDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CustomerLoginApp />
    </Wrapper>,
    customerLoginDiv
  );
}

const customerAccountDiv = document.getElementById("root-customer-account");
if (customerAccountDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CustomerAccountApp />
    </Wrapper>,
    customerAccountDiv
  );
}

const customerOrdersDiv = document.getElementById("root-customer-orders");
if (customerOrdersDiv !== null) {
  ReactDOM.render(
    <Wrapper>
      <CustomerOrdersApp />
    </Wrapper>,
    customerOrdersDiv
  );
}
