import $ from "jquery";

$(document).ready(function () {
  const body = $("body");
  const navbarCollapse = $(".navbar-collapse");

  const resizeObserver = new ResizeObserver(function (entries) {
    const isDesktop = window.matchMedia("(min-width: 992px)").matches;
    if (isDesktop) {
      navbarCollapse.collapse("hide");
    }
  });

  resizeObserver.observe(document.body);

  navbarCollapse.on("show.bs.collapse", function () {
    $('<div class="screen-darken"></div>').appendTo(".navbar");
  });

  navbarCollapse.on("hide.bs.collapse", function () {
    $(".screen-darken").hide().remove();
  });

  function scrollEvent() {
    if (window.scrollY >= 10) {
      body.addClass("is-scrolling");
    } else {
      body.removeClass("is-scrolling");
    }
  }

  scrollEvent();
  document.addEventListener("scroll", scrollEvent);

  body.on("click", ".screen-darken", function () {
    navbarCollapse.collapse("hide");
  });
});
